import React from 'react'
import Page from '../components/Page';
import Banner from '../components/content/Banner';
import TextSection from '../components/content/TextSection';

import titleImage from '../assets/img/sozialesKompetenztraining/titleImage.jpg'

const SozialesKompetenztraining: React.FC = (props) => {
    return (
        <Page id={"pageErgotherapie"}>
            <Banner 
            class={"ergotherapie_banner"}
            header={
                <h2>Gruppenangebot: Soziales Kompetenztraining</h2>
            }   
            titleImage={titleImage}
            > 
                <h3>Zielgruppe:</h3>
                <p>
                Unser ergotherapeutisches Training der sozialen Kompetenzen richtet sich an  Kinder verschiedenen Alters, 
                die Schwierigkeiten in Gruppen wie Schule, Kindergarten, Sportvereinen haben, die auf einen Mangel an sozialen 
                Kompetenzen zurück zu führen sind.
                Ziel ist es die Kinder darin zu unterstützen Handlungsmöglichkeiten zu entwickeln und anzuwenden, 
                die als sozial kompetent angesehen werden.
                </p>
            </Banner>
            <TextSection>
            <h3>Was ist eigentlich sozial kompetent!?</h3>
            <p>Die verschiedenen Aspekte „sozialer Kompetenz“ lassen sich in folgende fünf Bereiche unterteilen 
                (nach Candarella und Mererell 1997)
                <ul>
                   <li>Bildung und Gestaltung von Freundschaften oder 
                       anderen positiven Beziehungen zu Gleichaltrigen (z.B. anderen helfen, Perspektivenübernahme etc.)</li>
                   <li>Ausgewogenes Selbstmanagement
                        (Konflikte mit anderen bewältigen können, das eigene Verhalten kontrollieren und regulieren etc.)</li>
                   <li>Schulische Kompetenzen (jemanden um Hilfe bitten, wenn man z.B. etwas nicht versteht, die Anweisungen 
                       des Lehrers befolgen, Aufgaben zu Ende arbeiten, Autoritäten anerkennen etc.)</li>
                   <li>Kooperative Kompetenzen (soz. Regeln wie beispielsweise Klassenregeln anerkennen und 
                       befolgen, sich mit anderen etwas teilen, den anderen respektieren etc.)</li>
                   <li>Positive Selbstbehauptung und Durchsetzungsvermögen (Kontakt zu anderen aufnehmen und halten,
                        Gespräche oder Spiele beginnen können, die Freude am Miteinander erleben etc.)</li>
                </ul>
            </p>
            <h3>Methoden:</h3> 
            <ul>
                   <li>Verhaltenstherapeutischer Ansatz</li>
                   <li>Förderung der Eigen-und Fremdwahrnehmung</li>
                   <li>Sensorische Integrationstherapie</li>
                   <li>Handlungsorientierter Ansatz</li>
                   <li>Elterngespräche</li>
            </ul>
            <h3>Schwerpunkte:</h3> 
            <ul>
                   <li>Impulskontrolle</li>
                   <li>Frustrationstoleranz</li>
                   <li>Verbale und nonverbale Kommunikation</li>
                   <li>Kooperation</li>
                   <li>Körperwahrnehmung und Kraftdosierung</li>
                   <li>Situatives Verhalten</li>
                   <li>Selbstvertrauen</li>
                   <li>Selbstregulation</li>
                   <li>Reale Selbsteinschätzung</li>
                   <li>Erkennen und Ausdrücken von Gefühlen</li>
                   <li>Wünsche und Bedürfnisse angemessen äußern</li>
                   <li>Problem- und Konfliktlösungsstrategien</li>
                   <li>Verantwortung für sich und andere übernehmen</li>
            </ul>
            <h3>Ablauf einer Gruppenstunde:</h3> 
            <ul>
                <li>Eingangsphase <br /> 
                    Begrüßung, Zielformulierungen, verhaltenstherapeutisches Belohnungssystem</li>
                <li>Projektphase <br />
                    Erarbeiten von kleinen Projekten in Küche, Werkstatt und Bewegungsraum, 
                    spielerische Auseinadersetzung zum Thema Gefühle</li>
                <li>Reflexionsphase<br /></li>
            </ul>
            
            <h3>Organisation:</h3> 
            <p>Die Gruppe findet wöchentlich für 10mal statt. Jede Einheit dauert 90 Minuten. 
                Fester Bestandteil des Trainings sind zwei Elterngespräche</p>
            
            <h3>Gruppengröße:</h3> 
            <p>In der Regel 4 Kinder werden von 2 Therapeuten betreut</p>
            
            <h3>Kosten:</h3> 
            <p>Wenn eine Heilmittelverordnung (Rezept) vom Arzt vorliegt, 
                werden die Kosten von der Krankenkasse übernommen. </p>

            <h3>Voraussetzungen:</h3> 
            <p>Grundsätzlich keine. Wir haben die Erfahrung gemacht, 
                dass Kinder und Eltern mehr vom Training profitieren, wenn zuvor in der Einzelsituation Fähigkeiten 
                und Ressourcen, aber auch Schwierigkeiten des Kindes festgestellt werden. 
                Auf jeden Fall ist vorab eine telefonische Abklärung sinnvoll.</p>
          </TextSection>
          </Page>
    )
}

export default SozialesKompetenztraining
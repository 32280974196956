import React, { ReactNode } from 'react'

export interface ArticleTeaserSectionProps {
    id?: string
    title?: string
    children?: ReactNode[]
}

const ArticleTeaserSection: React.FC<ArticleTeaserSectionProps> = (props) => {

    let title = props.title ? <header className="major"><h2>{props.title}</h2></header> : null

    return (
        <React.Fragment>
        <div className="articel-teaser-section py-5 bg-light" id={props.id}>
            <div className="container">
                <h4 className="row">{title}</h4>
                <div className="row">
                    {props.children}
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default ArticleTeaserSection
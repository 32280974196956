import React from 'react'
import { RoutePath } from '../../App'
import { Link } from 'react-router-dom'

export interface BannerProps {
    class?: string
    titleImage?: string
    imageRoute?: string
    header: JSX.Element
    buttonTitle?: string
    secondaryButtonTitle?: string
    route?: RoutePath
    secondaryRoute?: RoutePath
    children?: React.ReactNode
}

const Banner: React.FC<BannerProps> = (props) => {

    let imageLink = props.imageRoute ? props.imageRoute : "#";

    if(!props.imageRoute) {
      imageLink = props.route ? props.route : "#";
    } 

    return (
      <section id={"banner"} className={"" + props.class + (!props.titleImage ? " text-center" : "")}>
        <div className="container py-5">
        <div className="row">
         { props.titleImage ? 
          <div className="col-lg-4 col-md-4 mx-auto d-md-none mb-5 mb-md-0">
            <a href={imageLink} className="image"> <img className="img-fluid" src={props.titleImage} alt="" /></a>
            { props.secondaryRoute ? <Link className="btn btn-secondary my-2 mt-4" to={props.secondaryRoute}>{props.secondaryButtonTitle}</Link> : null}
          </div> : null
          }
          <div className={"py-2 " + (props.titleImage ? " col-lg-5 col-md-5" : " col-lg-6 col-md-6")}>    
            <h1 className="fw-light">{props.header}</h1>
            <p className="lead">{props.children}</p>
            { props.route ? 
            <p>
              <Link className="btn btn-primary my-2 me-2" to={props.route}>{props.buttonTitle}</Link>
            </p>
            : null }
          </div>
          { props.titleImage ? 
          <div className="col-lg-5 col-md-5 mx-auto d-none d-md-block">
            <a href={imageLink} className="image"> <img className="img-fluid" src={props.titleImage} alt="" /></a>
            { props.secondaryRoute ? <Link className="btn btn-secondary my-2 mt-4" to={props.secondaryRoute}>{props.secondaryButtonTitle}</Link> : null}
          </div> : null
          }
        </div>
        </div>
        
      </section>
    )
}

export default Banner
import React from 'react'
import Page from '../components/Page';
import TextSection from '../components/content/TextSection';
import {Helmet} from "react-helmet";
import weihnachtenImage from '../assets/img/weihnachten/Kreuzwortraetsel_loesung.png'

const RaetselLoesung: React.FC = (props) => {
    return (
        <Page id={"pageImprint"}>
            <Helmet>
                <title>Lösung zum Weihnachtsrätsel</title>
            </Helmet>
            <TextSection>
            <h1>Lösung zum Weihnachtsrätsel</h1>
            <div><img className="img-fluid" src={weihnachtenImage} /></div>
            </TextSection>
          </Page>
    )
}

export default RaetselLoesung
import React, {ReactNode} from 'react'
import { Link } from 'react-router-dom'
import { RoutePath } from '../../App'

export interface PortraitProps {
    id?: string
    titleImage?: string
    title: string
    buttonTitle?: string
    route?: RoutePath
    children?: ReactNode
}

const Portrait: React.FC<PortraitProps> = (props) => {

    let titleImage: ReactNode = null
    if(props.titleImage) {
        titleImage = <div className="image"> <img src={props.titleImage} alt="" /></div>
    } else if(!props.titleImage && !props.children) {
        titleImage = <svg className="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"/><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
    }

    return (
        <div className="col-6 col-sm-4 d-flex portrait">
                <div className="card shadow-sm">
                    {titleImage}
                    <div className="card-body">
                        <h4>{props.title}</h4>
                        <p className="card-text">{props.children}</p>
                        { props.route ? 
                        <div className="d-flex justify-content-between align-items-center">
                            <Link className="btn btn-sm btn-outline-secondary" to={props.route}>{props.buttonTitle}</Link>
                        </div>
                        : null }
                    </div>
                </div>
        </div>
    )
}

export default Portrait
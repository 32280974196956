import React from 'react'
import Page from '../components/Page';
import Banner from '../components/content/Banner';
import TextSection from '../components/content/TextSection';

import titleImage from '../assets/img/ergotherapie/titleImage.jpg'
import erwachseneImage from '../assets/img/homepage/erwachsene.jpg'
import kinderImage from '../assets/img/homepage/kinder.jpg'
import ArticleTeaserSection from '../components/content/ArticleTeaserSection';
import ArticleTeaser from '../components/content/ArticleTeaser';
import { RoutePath as Routes } from '../App';
import {Helmet} from "react-helmet";

const Ergotherapie: React.FC = (props) => {
    return (
        <Page id={"pageErgotherapie"}>
            <Helmet>
                <title>Ergotherapie</title>
            </Helmet>
            <Banner 
            class={"ergotherapie_banner bg-light"}
            header={
                <h1>Was ist Ergotherapie?</h1>
            }   
            titleImage={titleImage}
            >
                <p>
                    Ziel aller ergotherapeutischen Behandlungen ist die Erlangung oder Wiederherstellung größtmöglicher Handlungsfähigkeit, 
                    Selbständigkeit und Eigenverantwortung.
                </p>

                <p>
                    Maßnahmen der Ergotherapie bedienen sich komplexer aktivierender und handlungsorientierter Methoden und Verfahren.
                    Ergotherapie hilft mit zahlreichen funktionellen, spielerischen, handwerklichen und gestalterischen Techniken
                    sowie lebenspraktischen Übungen.
                </p>

                <p>
                    Ergotherapie ist ein Heilmittel, das auf Verordnung des Haus- oder Facharztes erbracht und von den Krankenkassen bezahlt wird.
                </p>

                <p>
                    Die Therapie findet in der Praxis oder als Hausbesuch statt.
                </p>
            </Banner>

            <TextSection>
            <h2>Behandlung</h2> 
            <p>
                Zu Beginn der Behandlung steht die Befundaufnahme, aus der die Stärken und Schwierigkeiten abgeleitet werden. 
                Danach ist es uns wichtig, mit Ihnen gemeinsam die Ziele und Schwerpunkte der Therapie festzulegen, 
                dabei orientieren wir uns an Ihrem Alltag. <br /><br />
                Es stehen folgende Behandlungsmaßnahmen zur Verfügung:
            </p>
            <ul>
                <li>
                    Training alltäglicher Aktivitäten (ADL)
                </li>
                <li>
                    Sensorische Integrationstherapie
                </li>
                <li>
                    Bobathbehandlung
                </li>
                <li>
                    Fein- und grafomotorisches Training
                </li>
                <li>
                    Konzentrations- und Strategietraining
                </li>
                <li>
                    Hirnleistungstraining
                </li>
                <li>
                    Handwerkliche und spielerische Methoden
                </li>
                <li>
                    Gestalterische Methoden
                </li>
                <li>
                    Funktionelles Training
                </li>
                <li>
                    Manuelle Therapie
                </li>
                <li>
                    Methoden der Verhaltenstherapie
                </li>
            </ul>
            <p>
                Zur Behandlung gehört unverzichtbar die Beratung von Eltern und/oder Angehörigen. 
                In der Regel findet die Therapie als Einzelbehandlung statt, bei Bedarf ist auch eine 
                Gruppenbehandlung möglich. Es ist uns wichtig, regelmäßig den interdisziplinären Austausch 
                mit dem verordnenden Arzt, anderen Therapeuten, Schulen, Kindergärten, Seniorenheimen, 
                Pflegediensten u.a. zu pflegen.
            </p>
            </TextSection>
            <ArticleTeaserSection title="Mehr zu Ergotherapie" id={"moreAbout"}>
                <ArticleTeaser  
                  titleImage={kinderImage}
                       title={"Für Kinder"}
                      buttonTitle={"Mehr"}
                      route={Routes.ergotherapieKinder}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={erwachseneImage}
                       title={"Für Erwachsene"}
                      buttonTitle={"Mehr"}
                      route={Routes.ergotherapieErwachsene}>
                </ArticleTeaser>
              </ArticleTeaserSection>
          </Page>
    )
}

export default Ergotherapie
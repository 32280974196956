import React from 'react'
import Page from '../components/Page';
import Banner from '../components/content/Banner';
import titleImage from '../assets/img/konzentrationsTraining/titleImage.jpg'
import TextSection from '../components/content/TextSection';

const Konzentrationstraining: React.FC = (props) => {
    return (
        <Page id={"pageErgotherapie"}>
            <Banner 
            class={"ergotherapie_banner"}
            header={
                <h2>Gruppenangebot: Ergotherapeutisches Konzentrationstraining</h2>
            }   
            titleImage={titleImage}
            > 
                <h3>Zielgruppe:</h3>
                <p>
                    Zielgruppe unseres ergotherapeutischen Konzentrationstrainings sind Kinder vom Vorschulalter 
                    bis zum 4. Schuljahr. Sie haben Probleme in der Konzentration, Ausdauer und Impulsivität, 
                    oft können sie sich nicht gut in eine Gruppe einfügen. Diese Schwierigkeiten zeigen sich vor 
                    allem in der Schule und bei schulähnlichen Anforderungssituationen. Bei Kindergartenkindern 
                    treten sie z. B. im Morgenkreis, in der Vorschulgruppe, im Sportverein u.ä. auf. 
                </p>
            </Banner>
            <TextSection class="bg-light">
            <h3>Methoden:</h3> 
            <ul>
                   <li>Marburger Konzentrationstraining nach Krowatschek</li>
                   <li>Verhaltenstherapeutischer Ansatz</li>
                   <li>Förderung sozialer Kompetenzen</li>
                   <li>Förderung der Wahrnehmung</li>
                   <li>Förderung der Graphomotorik</li>
            </ul>
            <h3>Ziele:</h3> 
            <ul>
                   <li>Die Kinder lernen, sich in einer Lerngruppe zurecht zu finden und einzufügen.</li>
                   <li>Die Kinder lernen eine Aufgabe (z.B. Hausaufgabe) selbständig zu strukturieren und zu planen.</li>
                   <li>Ein reflexiver Arbeitsstil wird aufgebaut um impulsivem, überstürztem Handeln entgegen zu wirken.</li>
                   <li>Ein adäquater Umgang mit Fehlern wird eingeübt.</li>
                   <li>Die Kinder gewinnen Zutrauen in ihr eigenes Können und verbessern 
                       so ihre Leistungsbereitschaft.</li>
                   <li>Intensive Beratung und Begleitung der Eltern, so dass sie in der Lage sind, die aufgebauten Strategien 
                       und Verhaltensweisen zu Hause zu nutzen.</li>
            </ul>
             <h3>Vorgehensweise:</h3>
             <p>
                Jede Einheit hat den gleichen strukturierten Aufbau – Rituale geben Kindern Sicherheit und Orientierung.
                In jeder Stunde wird Entspannung geübt, gespielt und konzentriert gearbeitet, 
                am Ende jeder Stunde findet eine Abschlussrunde/ Reflexion statt.
            </p>
            <h3>Organisation:</h3>
            <p>Die Gruppe ist ein in sich geschlossenes, zeitlich begrenztes Angebot und findet 10x regelmäßig mittwochs
              statt. Jede Einheit dauert 75-90 Minuten. 4-6 Kinder werden von 1-2 Therapeuten betreut.
              Wir bemühen uns, die Gruppen bzgl. Alter und Fähigkeiten homogen zu gestalten. 
              Für angehende Schulkinder findet in der Regel vor den Sommerferien eine Vorschulgruppe statt. 
              Für die anderen Altersgruppen bilden wir die Gruppen nach Bedarf. <strong>Fester Bestandteil des Trainings sind 
              zwei Elternabende, an denen im Idealfall beide Elternteile teilnehmen.</strong></p>
              <h3>Kosten:</h3>
              <p>Wenn eine Heilmittelverordnung (Rezept) vom Arzt vorliegt, werden die Kosten von der Krankenkasse übernommen. 
                  Für den Elternabend erheben wir einen Unkostenbeitrag.</p>
              <h3>Voraussetzungen:</h3>
              <p>Grundsätzlich keine. Wir haben die Erfahrung gemacht, dass Kinder und Eltern mehr vom Training profitieren, 
                  wenn zuvor in der Einzelsituation Fähigkeiten und Ressourcen, aber auch Schwierigkeiten des Kindes 
                  festgestellt werden. Auf jeden Fall ist vorab eine telefonische Abklärung sinnvoll.</p>
            </TextSection>
          </Page>
    )
}

export default Konzentrationstraining
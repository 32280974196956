import Logo from "./Logo"
import { Link } from 'react-router-dom'
import { RoutePath } from '../App'

function Header() {
    return (
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Logo />
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="navbar-collapse collapse show" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <Link className="nav-link" to={RoutePath.start}>Start</Link>
                    </li>
                    <li className="nav-item active">
                        <Link className="nav-link" to={RoutePath.ergotherapie}>Ergotherapie</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Über uns
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item" to={RoutePath.praxis}>Praxis</Link>
                            <Link className="dropdown-item" to={RoutePath.team}>Unser Team</Link>
                        </div>
                    </li>
                    <li className="nav-item active">
                        <Link className="nav-link" to={RoutePath.ergotherapieKinder}>Kinder & Jugendliche</Link>
                    </li>
                    {/*}
                    <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" to={RoutePath.ergotherapieKinder} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Kinder & Jugendliche</Link>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item" to={RoutePath.ergotherapieKinder}>Könnte Ergotherapie Ihrem Kind helfen</Link>
                            <Link className="dropdown-item" to={RoutePath.konzentrationstraining}>Ergotherapeutisches Konzentrationstraining</Link>
                            <Link className="dropdown-item" to={RoutePath.sozialesKompetenztraining}>Soziales Kompetenztraining</Link>
                        </div>
                    </li>
                    {**/}
                    <li className="nav-item active">
                        <Link className="nav-link" to={RoutePath.ergotherapieErwachsene}>Erwachsene</Link>
                    </li>
                </ul>
            </div>

          </nav>
        </header>
    )
}

export default Header;
import React from 'react'
import Page from '../components/Page';
import Banner from '../components/content/Banner';
import ArticleTeaserSection from '../components/content/ArticleTeaserSection';
import ArticleTeaser from '../components/content/ArticleTeaser';
import TextSection from '../components/content/TextSection';

import titleImage from '../assets/img/ergotherapieKinder/titleImage.jpg'
import konzentrationstrainingImage from '../assets/img/ergotherapieKinder/konzentrationsTraining.jpg'
import { RoutePath as Routes } from '../App';
import {Helmet} from "react-helmet";

const ErgotherapieKinder: React.FC = (props) => {
    return (
        <Page id={"pageErgotherapie"}>
            <Helmet>
                <title>Kinder & Jugendliche</title>
            </Helmet>
            <Banner 
            class={"ergotherapie_banner"}
            header={
                <React.Fragment>
                <h1>Ergotherapie für Kinder</h1>
                <h2>Woran erkennen Sie, dass Ergotherapie Ihrem Kind weiter helfen könnte?</h2>
                </React.Fragment>
            }   
            titleImage={titleImage}
            > 
                <ul>
                    <li>Ihr Kind entwickelt sich nicht altersgemäß</li>
                    <li>Ihr Kind hat Lernprobleme</li>
                    <li>Ihr Kind hat Probleme, seine Aufmerksamkeit zu steuern</li>
                    <li>Ihr Kind ist immer in Bewegung</li>
                    <li>Ihr Kind ist aggressiv</li>
                    <li>Ihr Kind ist auffallend zurückhaltend</li>
                    <li>Ihr Kind ist ungeschickt, eckt häufig an</li>
                    <li>Ihr Kind hat Anpassungs- und Schlafprobleme</li>
                    <li>Ihr Kind wirkt unsicher bei Bewegungsanforderungen</li>
                    <li>Ihr Kind malt und schneidet ungern</li>
                    <li>Ihr Kind hat Schwierigkeiten beim Schreiben  <br />(z.B. langsam, unleserlich, hoher Druck)</li>
                </ul>
            </Banner>
            <TextSection class="bg-light">
            <h2>Gründe hierfür können sein:</h2> 
            <ul>
                   <li>Entwicklungsstörungen, wie z.B. körperliche, soziale, emotionale und kognitive Schwierigkeiten oder Behinderungen</li>
                   <li>Bewegungsstörungen mit Beeinträchtigung von Grob- und Feinmotorik, Grafomotorik und Lateralitätsentwicklung</li>
                   <li>Störungen bei der Aufnahme und Verarbeitung von Sinnesreizen (u.a. im visuellen oder auditiven Bereich oder der Körperwahrnehmung)</li>
                   <li>Störung des Verhaltens in Form von Angst, Abwehr, Aggression, Passivität oder Hyperaktivität</li>
                   <li>Störung der Aufmerksamkeit und Konzentration</li>
                   <li>Beeinträchtigung der Lernfähigkeit</li>
            </ul>
            </TextSection>
            {/*}
             <ArticleTeaserSection title="Unsere Gruppenangebote für Kinder" id={"moreAbout"}>
                <ArticleTeaser  
                  titleImage={konzentrationstrainingImage}
                       title={"Ergotherapeutisches Konzentrationstraining"}
                      buttonTitle={"Mehr"}
                      route={Routes.konzentrationstraining}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={titleImage}
                       title={"Soziales Kompetenztraining"}
                      buttonTitle={"Mehr"}
                      route={Routes.sozialesKompetenztraining}>
                </ArticleTeaser>
              </ArticleTeaserSection>
            {*/}
          </Page>
    )
}

export default ErgotherapieKinder
import React from 'react'
import logo from '../assets/img/logo.png';
import { Link } from 'react-router-dom'
import { RoutePath } from '../App'

const Logo: React.FC = (props) => {
    return (
        <Link className="logo navbar-brand" to={RoutePath.start}>
          <img src={logo} alt={"Praxis für Ergotherapie"} />
        </Link>
    )
}

export default Logo
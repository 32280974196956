import React from 'react'
import Page from '../components/Page';
import Banner from '../components/content/Banner';
import ArticleTeaserSection from '../components/content/ArticleTeaserSection';
import ArticleTeaser from '../components/content/ArticleTeaser';

import titleImage from '../assets/img/homepage/titelImage.jpg'
import weihnachtsImage from '../assets/img/homepage/weihnachten3.png'
import erwachseneImage from '../assets/img/homepage/erwachsene.jpg'
import kinderImage from '../assets/img/homepage/kinder.jpg'
import teamImage from '../assets/img/homepage/team.jpg'
import { RoutePath } from '../App';
import bewegungsraumImage from '../assets/img/praxis/bewegungsraum.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faHome } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";

const Startpage: React.FC = (props) => {
    return (
        <Page>
              <Helmet>
                <title>Gemeinschaftspraxis für Ergotherapie, Uta Bartel und Julia Söffing-Bartel</title>
              </Helmet>
              <Banner 
                header={
                  <h1>Willkommen <br />
                    auf unserer Seite!
                  </h1>
                }
                buttonTitle="Was ist eigentlich Ergotherapie?"
                route={RoutePath.ergotherapie}
                titleImage={titleImage}
              >
                    <div className="alert alert-secondary" role="alert">
                      Wir sind umgezogen! Sie finden uns nun in unseren neuen Räumen am Jubiläumsplatz 2. Telefonnummer und E-Mail bleiben gleich.
                    </div>
                    Hier haben Sie die Möglichkeit, sich über uns,
                    die Praxis und unser breitgefächertes Angebot zu informieren.
                    Für ein individuelles Beratungsgespräch kontaktieren
                    Sie uns <a href="tel:/00492104286280">telefonisch</a> oder senden 
                    Sie uns eine <a href="mailto:bartel.ergotherapie@yahoo.de">eMail</a>.
              </Banner>

              <ArticleTeaserSection title="Über uns">
                <ArticleTeaser  
                       title={"Die Praxis"}
                      buttonTitle={"Mehr"}
                      route={RoutePath.praxis}
                      titleImage={bewegungsraumImage}>
                </ArticleTeaser>
                <ArticleTeaser  
                titleImage={teamImage}
                       title={"Unser Team"}
                      buttonTitle={"Mehr"}
                      route={RoutePath.team}>
                </ArticleTeaser>
                <ArticleTeaser  
                       title={"Kontakt"}
                >
                  <div className="row-cols-1 row-cols-sm-1 row-cols-md-1 g-1">
                    <p>Für Terminabsprachen oder Fragen stehen wir, oder unser Anrufbeantworter, Ihnen gerne telefonisch Montags bis Freitags zwischen 08.00 Uhr und 18.00 Uhr zur Verfügung.</p>
                  </div>
                  <br />  
                  <div className="mb-1 float-start">
                  <ul className="list-unstyled">
                        <li className="contactLink icon solid fa-envelope"><FontAwesomeIcon className="faIcon" color="#008c69" icon={faEnvelope} /><a href="mailto:bartel.ergotherapie@yahoo.de" className="contactLink">bartel.ergotherapie@yahoo.de</a></li>
                        <li className="contactLink icon solid fa-phone"><FontAwesomeIcon className="faIcon" color="#008c69" icon={faPhone} /><a href="tel:/00492104268680" className="contactLink">02104 / 286280</a></li>
                        <li className="contactLink icon solid fa-home"><FontAwesomeIcon className="faIcon" color="#008c69" icon={faHome} /><a href="https://goo.gl/maps/Z7ArjrqnUnxb13SA7" target="_blank" rel="noopener noreferrer" className="contactLink">Jubiläumsplatz 2<br />
                            40822 Mettmann</a></li>
                    </ul>
                  </div>
                </ArticleTeaser>
              </ArticleTeaserSection>

              <ArticleTeaserSection title="Ergotherapie">
                <ArticleTeaser  
                  titleImage={kinderImage}
                       title={"Für Kinder"}
                      buttonTitle={"Mehr"}
                      route={RoutePath.ergotherapieKinder}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={erwachseneImage}
                       title={"Für Erwachsene"}
                      buttonTitle={"Mehr"}
                      route={RoutePath.ergotherapieErwachsene}>
                </ArticleTeaser>
              </ArticleTeaserSection>

          </Page>
    )
}

export default Startpage
import React from 'react'
import Header from "./Header"
import Footer from "./Footer"
import Content from "./Content"
import ScrollToTop from "./ScrollToTop"

export interface PageProps {
    id?: string
    children?: React.ReactNode
}

const Page: React.FC<PageProps> = (props) => {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Header />
          {props.children}
        <Footer />
      </React.Fragment>
    )
}

export default Page
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'

export interface ExternalLinkProps {
    href: string
    text: string
    className?: string
    hidden?:boolean
}

const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
    if(props.hidden) {
        return null;
    }
    return (
      <React.Fragment>
        <a href={props.href} className={props.className} target="_blank" ><FontAwesomeIcon className="linkIcon" color="#008c69" icon={faExternalLink} />{  props.text }</a>
      </React.Fragment>
    )
}

export default ExternalLink
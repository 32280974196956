import { Link } from 'react-router-dom'
import { RoutePath } from '../App'
import map from '../assets/img/map.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faHome } from '@fortawesome/free-solid-svg-icons'
import ExternalLink from './ExternalLink'

function Footer() {
    return (
        <footer>
        <div className="text-muted py-5 container">
        <div className="row">
            <div className="col">
                <h4 className="text-dark">Kontaktieren Sie uns</h4>
                <div className="">
                    <p>Für Terminabsprachen oder Fragen stehen wir, oder unser Anrufbeantworter, Ihnen gerne telefonisch Montags bis Freitags zwischen 08.00 Uhr und 18.00 Uhr zur Verfügung.</p>
                </div>
                <div className="mb-1">
                    <ul className="list-unstyled">
                        <li className="contactLink icon solid fa-envelope"><FontAwesomeIcon className="faIcon" color="#008c69" icon={faEnvelope} /><a href="mailto:bartel.ergotherapie@yahoo.de" className="contactLink">bartel.ergotherapie@yahoo.de</a></li>
                        <li className="contactLink icon solid fa-phone"><FontAwesomeIcon className="faIcon" color="#008c69" icon={faPhone} /><a href="tel:/00492104268680" className="contactLink">02104 / 286280</a></li>
                        <li className="contactLink icon solid fa-home"><FontAwesomeIcon className="faIcon" color="#008c69" icon={faHome} /><a href="https://goo.gl/maps/Z7ArjrqnUnxb13SA7" target="_blank" rel="noopener noreferrer" className="contactLink">Jubiläumsplatz 2<br />
                            40822 Mettmann</a></li>
                    </ul>
                </div>
            </div>
            
            <div  className="map col-sm-6 col-12">
                <img className="img-fluid" src={map} />
                
            </div>
            
        </div>
        <div className="row footer-footer">
            <div className="col-10"/>
            <div>
                <ExternalLink hidden={true} className={"float-end mr-0 pr-0"} href={"https://goo.gl/maps/zmg5wy8rbsfTLL179"} text={"Route in Google Maps öffnen"}/>
                <Link className="mr-auto float-start" to={RoutePath.impressum}>Impressum</Link>
                <Link className="float-start" to={RoutePath.datenschutz}>Datenschutz</Link>
            </div>
        </div>
        </div>
        </footer>
    )
}

export default Footer;

/*
                        
*/
import React from 'react'
import Page from '../components/Page';
import Banner from '../components/content/Banner';
import ArticleTeaserSection from '../components/content/ArticleTeaserSection';
import ArticleTeaser from '../components/content/ArticleTeaser';
import TextSection from '../components/content/TextSection';

import titleImage from '../assets/img/praxis/titleImage.jpg'
import bewegungsraumImage from '../assets/img/praxis/bewegungsraum.jpg'
import werkstattImage from '../assets/img/praxis/werkstatt.jpg'
import kuecheImage from '../assets/img/praxis/kueche.jpg'
import behandlungsraumImage from '../assets/img/praxis/behandlungsraum.jpg'
import warteraumImage from '../assets/img/praxis/warteraum.jpg'
import {Helmet} from "react-helmet";

const Praxis: React.FC = (props) => {
    return (
        <Page>
              <Helmet>
                <title>Praxis</title>
              </Helmet>
              
              <TextSection>
                  <h1>Die Praxis</h1>
                    <p>
                         Seit Januar 2000 führt Uta Bartel die Praxis in Mettmann. Seit Januar 2022 führen Uta Bartel und Julia Söffing-Bartel die Praxis gemeinsam als Gemeinschaftspraxis. Im Laufe der Jahre bildete sich ein eingespieltes Team von Therapeutinnen. Jede von ihnen hat verschiedene Zusatzqualifikationen, durch die sich ein breites Angebot ergibt.
                    </p>

                    <p>
                        Regelmäßige Weiterbildungen sind selbstverständlich. Es ist uns wichtig, Sie einfühlsam und ganzheitlich zu behandeln. 
                        Deshalb nehmen wir uns viel Zeit, die Ursachen Ihrer Schwierigkeiten zu ergründen, Ihre Ressourcen aufzuspüren und zu nutzen.
                    </p>

                    <p>
                        Die Praxis liegt zentral in der Talstraße nahe des Regiobahnhaltepunkt im Allbahaus im 3. Stock. Das Haus ist mit einem Aufzug ausgestattet.
                    </p>

                    <p>
                        Die Praxisräume bieten eine freundliche, angenehme Atmosphäre. Vom großen Bewegungsraum über die Werkstatt bis hin zur lebenspraktischen Küche ist alles vorhanden, 
                        um eine individuell zugeschnittene Therapie durchführen zu können.
                    </p>
              </TextSection>
              <ArticleTeaserSection title="Unsere Räumlichkeiten">
                <ArticleTeaser  
                  titleImage={bewegungsraumImage}
                       title={"Therapieraum"}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={werkstattImage}
                       title={"Therapie-/Werkraum"}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={kuecheImage}
                       title={"Therapieraum/Alltagstraining"}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={behandlungsraumImage}
                       title={"Therapieraum"}>
                </ArticleTeaser>
                <ArticleTeaser  
                  titleImage={warteraumImage}
                       title={"Warteraum"}>
                </ArticleTeaser>
              </ArticleTeaserSection>
          </Page>
    )
}

export default Praxis

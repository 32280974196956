import React from 'react'
import Page from '../components/Page';
import TextSection from '../components/content/TextSection';
import {Helmet} from "react-helmet";
import weihnachtenImage from '../assets/img/weihnachten/Kreuzwortraetsel.png'
import {RoutePath} from '../App'
import { Link } from 'react-router-dom'

const Weihnachten: React.FC = (props) => {
    return (
        <Page id={"pageImprint"}>
            <Helmet>
                <title>Weihnachtsrätsel</title>
            </Helmet>
            <TextSection>
            <h1>Weihnachtsrätsel</h1>
            <div><img className="img-fluid" src={weihnachtenImage} /></div>
            <p>
              <Link className="btn btn-primary my-2 me-2" to={RoutePath.raetselLoesung}>{"Zur Lösung"}</Link>
              <a className="btn btn-secondary my-2" href={"https://ergotherapie-mettmann.de/Kreuzwortraetsel.pdf"}>{"Rätsel herunterladen"}</a>
            </p>
            </TextSection>
          </Page>
    )
}

export default Weihnachten
import React from 'react'
import Page from '../components/Page';
import Banner from '../components/content/Banner';
import titleImage from '../assets/img/erwachsene/titleImage.jpg'
import TextSection from '../components/content/TextSection';
import {Helmet} from "react-helmet";

const ErgotherapieErwachsene: React.FC = (props) => {
    return (
        <Page id={"pageErgotherapie"}>
            <Helmet>
                <title>Erwachsene</title>
            </Helmet>
            <Banner 
            class={"ergotherapie_banner"}
            header={
                <React.Fragment>
                <h1>Ergotherapie für Erwachsene</h1>
                <h2>Woran erkennen Sie, dass Ergotherapie Ihnen weiter helfen könnte?</h2>
                </React.Fragment>
            }   
            titleImage={titleImage}
            > 
                <p>
                    Sie haben Schwierigkeiten bei der Verrichtung alltäglicher Aufgaben wie z.B. Selbstversorgung, 
                    Haushaltsführung, Mobilität, Arbeit etc.
                </p>

            </Banner>
            <TextSection class="bg-light">
            <h2>Gründe hierfür können sein:</h2> 
            <ul>
                   <li>Neurologische Erkrankungen</li>
                   <li>Rheumatische Erkrankungen</li>
                   <li>Psychische Erkrankungen</li>
                   <li>Bewegungs- und Sensibilitätsstörungen mit Folgeschäden wie ungünstige Haltungs- und Bewegungsmuster</li>
                   <li>Kognitive Probleme wie z.B. Konzentrations- und Gedächnisstörung, Orientierungsstörungen, Neglect, 
                       Apraxien (Störung der Handlungsplanung), visuelle oder akustische Wahrnehmungsstörungen sowie 
                       räumlich-konstruktive Störungen.</li>
            </ul>
            </TextSection>
          </Page>
    )
}

export default ErgotherapieErwachsene
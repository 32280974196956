import React from 'react'

export interface TextSectionProps {
    children?: React.ReactNode
    class?: string
}

const TextSection: React.FC<TextSectionProps> = (props) => {
    return (
      <section className={(props.class ? props.class : "") }>
        <div className="container lead py-5 ">
        {props.children}
        </div>
      </section>
    )
}

export default TextSection
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Startpage from "./pages/Startpage"
import Ergotherapie from "./pages/Ergotherapie"
import ErgotherapieErwachsene from "./pages/ErgotherapieErwachsene"
import ErgotherapieKinder from "./pages/ErgotherapieKinder"
import Impressum from "./pages/Impressum"
import Datenschutz from "./pages/Datenschutz"
import Konzentrationstraining from "./pages/Konzentrationstraining"
import Praxis from "./pages/Praxis"
import SozialesKompetenztraining from "./pages/SozialesKompetenztraining"
import Team from './pages/Team'
import Weihnachten from "./pages/Weihnachten"
import RaetselLoesung from "./pages/RaetselLoesung"


import './App.css';

export enum RoutePath {
  start = "/",
  ergotherapie = "/ergotherapie/",
  ergotherapieKinder = "/ergotherapie-kinder/",
  sozialesKompetenztraining = "/soziales-kompetenztraining/",
  konzentrationstraining = "/konzentrationstraining/",
  praxis = "/praxis/",
  team = "/team/",
  ergotherapieErwachsene = "/ergotherapie-erwachsene/",
  impressum = "/impressum/",
  datenschutz = "/datenschutz/",
  weihnachten = "/weihnachtsraetsel",
  raetselLoesung = "/raetsel-loesung"
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path={RoutePath.start} element={<Startpage/>}/>
        <Route path={RoutePath.ergotherapie} element={<Ergotherapie/>}/>
        <Route path={RoutePath.ergotherapieErwachsene} element={<ErgotherapieErwachsene/>}/>
        <Route path={RoutePath.ergotherapieKinder} element={<ErgotherapieKinder/>}/>
        <Route path={RoutePath.impressum} element={<Impressum/>}/>
        <Route path={RoutePath.datenschutz} element={<Datenschutz/>}/>
        <Route path={RoutePath.konzentrationstraining} element={<Konzentrationstraining/>}/>
        <Route path={RoutePath.praxis} element={<Praxis/>}/>
        <Route path={RoutePath.sozialesKompetenztraining} element={<SozialesKompetenztraining/>}/>
        <Route path={RoutePath.team} element={<Team/>}/>
        <Route path={RoutePath.weihnachten} element={<Weihnachten/>}/>
        <Route path={RoutePath.raetselLoesung} element={<RaetselLoesung/>}/>
      </Routes>
    </Router>
  );
}

export default App;
